import Vue from "vue";
import VueRouter from "vue-router";
import _import from "@/router/_import";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/google",
    name: "google",
    component: _import("googleConfig"),
    meta: {
      title: "Google爬虫配置",
    },
  },
  {
    path: "/ins",
    name: "ins",
    component: _import("insConfig"),
    meta: {
      title: "Ins爬虫配置",
    },
  },
  {
    path: "/facebook",
    name: "facebook",
    component: _import("facebookConfig"),
    meta: {
      title: "FaceBook爬虫配置",
    },
  },
  {
    path: "/keyword",
    name: "keyword",
    component: _import("keywordList"),
    meta: {
      title: "关键词管理",
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next("/google");
    return;
  }
  next();
});

export default router;

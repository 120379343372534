"use strict";

import Vue from "vue";
import axios from "axios";
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";

let config = {
  baseURL: "https://octopus-admin.fanyou-online.com/",
  // withCredentials: true,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // request拦截
    return config;
  },
  function (error) {
    // 异常拦截
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    // response拦截
    return response;
  },
  function (error) {
    // 异常拦截
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;

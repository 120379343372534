<template>
  <div id="app">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item
        v-for="route in routes"
        :key="route.path"
        :index="route.path"
        >{{ route.meta.title }}</el-menu-item
      >
    </el-menu>
    <router-view />
  </div>
</template>
<script>
import { routes } from "@/router";
export default {
  data() {
    return {
      activeIndex: routes[0].path,
      routes,
    };
  },
  methods: {
    handleSelect(route) {
      if (route === this.$route.path) {
        return;
      }
      this.$router.push(route);
    },
  },
  mounted() {
    this.activeIndex = this.$route.path;
  },
};
</script>
<style lang="less"></style>
